import styled from '@emotion/styled'
import {
  IonCard,
  IonCardContent,
  IonCardHeader, IonCardTitle,
  IonCol,
  IonIcon,
  IonImg,
  IonNote,
  IonRow,
  IonSpinner,
  IonGrid,
  IonText,
  Animation,
  CreateAnimation, IonCardSubtitle,
  createAnimation, IonLoading, IonToolbar, IonTitle, IonButtons, IonButton
} from '@ionic/react'
import "../../theme/variables.css"
import ScreenWakeLock from 'screen-wake-lock';
import Wrapper from "../../components/Header/Wrapper"
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Redirect, useHistory, useLocation, useParams} from 'react-router'
import { getSlideshowData } from '../../util/api/Slideshow'
import {getEndPoint, SLIDESHOW} from "../../util/api/enpoints";
import {fetchEventData, setAuctionCode, setEventCode} from "../../redux/slices/eventSlice";
import {setPrivacyEventCode} from "../../redux/slices/privacyPolicySlice";
import userEvent from "@testing-library/user-event";
import moment from "moment";
import {HubConnection, HubConnectionBuilder, JsonHubProtocol, LogLevel} from "@microsoft/signalr";
import {
  fetchSlideshowItemsData, updateActiveAuctionItems,
  updateActiveItem,
  updateSlideshowItemOnBid
} from "../../redux/slices/slideShowItemSlice";
import store from "../../redux/store";
import {updateItemOnBid} from "../../redux/slices/auctionItemsSlice";
import SlideshowDetailPage from "./SlideshowDetailPage";
import {current} from "@reduxjs/toolkit";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Img from "../../components/Header/Img";
import {expandOutline} from "ionicons/icons";
import {getConfig} from "../../AppConfig";

interface Params {
  eventcode: string,
  location?: string,
  timeframe?: string
  // list?: string
  pagecount?: string
}

const { apiUrl } = getConfig()
const PROD_URL = apiUrl + "/hubs/auction"

const SlideshowPage: React.FC = () => {
  const handle = useFullScreenHandle();
  let uriLocation = useLocation();
  const list = uriLocation.pathname.includes('/list')
  const animationRef = useRef < HTMLIonCardElement >();
  const { eventcode, location, timeframe, pagecount } = useParams<Params>()
  const dispatch = useDispatch()

  const duration = Number((Number(timeframe) > 0 ? Number(timeframe) * 1000 : 10000))
  const [message, setMessage] = useState<string | undefined>(undefined)
  const [lstMessage, setListMessage] = useState<string | undefined>(undefined)

  const domainName =  window.location.hostname
  const domainNames = domainName.split('.')
  const auctioncode = (domainNames && domainNames.length > 0) ? domainNames[0] : 'app'

  const slideshowStateItems = useSelector((state: any) => state.slideshowItems);
  const auctionStateItems = useSelector((state: any) => state.slideshowItems.auctionitems);
  const activeStateItem = useSelector((state: any) => state.slideshowItems.activeItem);
  const eventData = useSelector((state: any) => state.event)

  useEffect(() => {
    const url = getEndPoint('EVENT_ENDPOINT')
    const eventData = {
      url,
      eventcode: eventcode,
      auctioncode: auctioncode
    }
    dispatch(fetchEventData(eventData))
    dispatch(setEventCode(eventcode))
    dispatch(setAuctionCode(auctioncode))
    dispatch(setPrivacyEventCode(eventcode))
  }, [eventcode, auctioncode])


  async function start(connection: any) {
    try {
      await connection.start();
    } catch (err) {
      console.log(err);
    }
  };

  const currencyFormat = (num: Number) => {
    return '$' + num.toFixed(0) //.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  useEffect(() => {
    var url = getEndPoint(SLIDESHOW)  + '/' + auctioncode + '/' + eventcode
    if (location) {
      url += '/' + location
    }
    store.dispatch(fetchSlideshowItemsData(url))
  }, [])

  useEffect(() => {
    // localStorage.setItem("token", slideshowStateItems.token)
    let connection: HubConnection

    const token = slideshowStateItems.token
    if (token) {
      connection =  new HubConnectionBuilder()
          .withUrl(PROD_URL, { accessTokenFactory: () => { return token as string } })
          .withAutomaticReconnect()
          .withHubProtocol(new JsonHubProtocol())
          .configureLogging(LogLevel.Debug)
          .build()

      connection?.on("ReceiveJSON", (msg: any) => {
        const parsedData = JSON.parse(msg.jsonString)

        if (parsedData.type === 'itemupdate') {
          store.dispatch(
              updateSlideshowItemOnBid({
                ...parsedData
              })
          )
        }
      })

      start(connection);
    }
    // setSlideShowRecs(slideshowStateItems.auctionitems)
  }, [slideshowStateItems.auctionitems])

  useEffect(() => {
    let imageIndex = 0;
    let currentListItemCount = 0
    const listItemsCount = (Number(pagecount) > 0 ? Number(pagecount) : 6);

    const currentListArray = auctionStateItems?.slice(currentListItemCount, currentListItemCount+listItemsCount)
    if (currentListArray && (currentListArray.length + currentListItemCount) >= auctionStateItems.length) {
      currentListItemCount = 0
    }
    else {
      currentListItemCount += listItemsCount
    }
    store.dispatch(updateActiveAuctionItems(currentListArray))

    if ((!list && auctionStateItems && auctionStateItems.length > 0) || (auctionStateItems && auctionStateItems.length >= listItemsCount && list)) {
      const imageInterval = setInterval(() => {
        // handleEndPlayAnimation()
        if (imageIndex < auctionStateItems?.length - 1) {
          imageIndex += 1;

          const currentListArray = auctionStateItems.slice(currentListItemCount, currentListItemCount+listItemsCount)
          if (currentListArray && (currentListArray.length + currentListItemCount) >= auctionStateItems.length) {
            currentListItemCount = 0
          }
          else {
            currentListItemCount += listItemsCount
          }
          store.dispatch(updateActiveAuctionItems(currentListArray))
        } else {
          imageIndex = 0;
        }
        store.dispatch(updateActiveItem(auctionStateItems[imageIndex]))
        handleBeginPlayAnimation()
      }, duration);
      return () => clearInterval(imageInterval);
    }
  }, [auctionStateItems]);

  useEffect(() => {
    const interval = setInterval(() => {
      const then: any = moment(activeStateItem?.closing, "YYYY-MM-DD hh:mm:ss")
      const now: any = moment()

      const calcSeconds = then.diff(now, 'seconds')
      const days = Math.floor(calcSeconds / 24 / 60 / 60);
      const hoursLeft = Math.floor((calcSeconds) - (days * 86400));
      const hours = Math.floor(hoursLeft / 3600);
      const minutesLeft = Math.floor((hoursLeft) - (hours * 3600));
      const minutes = Math.floor(minutesLeft / 60);
      const remainingSeconds = calcSeconds % 60;

      function pad(n) {
        return (n < 10 ? "0" + n : n);
      }

      // console.log(pad(days) + ":" + pad(hours) + ":" + pad(minutes) + ":" + pad(remainingSeconds))
      if (days > 0) {
        setMessage( "Bidding Ends in " + pad(days) + "d " + pad(hours) + "h " + pad(minutes) + 'm ' + pad(remainingSeconds) + 's')
        setListMessage(pad(days) + "d " + pad(hours) + "h " + pad(minutes) + 'm ' + pad(remainingSeconds) + 's')
      }
      else if (hours > 0) {
        setMessage( "Bidding Ends in " + pad(hours) + "h " + pad(minutes) + 'm ' + pad(remainingSeconds) + 's')
        setListMessage(pad(hours) + "h " + pad(minutes) + 'm ' + pad(remainingSeconds) + 's')
      }
      else if (minutes > 0) {
        setMessage( "Bidding Ends in " + pad(minutes) + "m " + pad(remainingSeconds) + 's')
        setListMessage(pad(minutes) + 'm ' + pad(remainingSeconds) + 's')
      } else if (remainingSeconds > 0) {
        setMessage( "Bidding Ends in " + pad(remainingSeconds) + 's')
        setListMessage(pad(remainingSeconds) + 's')
      }
      else {
        setMessage('Bidding Closed!')
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [activeStateItem?.closing])


  const pad = (n) => {
    return (n < 10 ? "0" + n : n);
  }

  const handleBeginPlayAnimation = () => {
    if (animationRef.current !== null) {
      const animation = createAnimation()
          .addElement(animationRef.current)
          .duration(1000)
          // .fromTo(
          //     'transform',
          //     'translateX(0)',
          //     'translateX(600px) '
          // ).fromTo('opacity', '1', '0.05')
          // .fromTo(
          //     'transform',
          //     'translateX(600px)',
          //     'translateX(0) '
          // )
          .fromTo('opacity', '1', '0')
          .easing('ease-out')
          .duration(1000)
          .fromTo('opacity', '0.01', '1')
          .easing('ease-in')

      animation.play();
    }
  };

  const getHighBidderText = (highbidder: string) => {
    if (highbidder && highbidder?.length > 0) {
      return "High Bidder: " + highbidder
    }
    else {
      return "No High Bidder"
    }
  }

  const getStatusText = (activeStateItem) => {
    if (activeStateItem?.quantityavailable > 0) {
      return currencyFormat(Number(activeStateItem?.lastbid))
    }
    else if (activeStateItem?.quantityunlimited) {
      return "Unlimited"
    }
    else {
      return "Sold Out"
    }
  }

  return (
    <>
      <ScreenWakeLock />
      <FullScreen handle={handle}>

      <Wrapper>
        <IonToolbar color={'white'}>
          <IonTitle className="ion-text-center">
            <Img
                alt="logo"
                src={eventData.logourl}
            />
          </IonTitle>
          {!handle.active &&
              <IonButtons slot="end">
                <IonButton id="notification-button" ion-button fill="clear" onClick={handle.enter}>
                  <IonIcon
                      // slot="icon-only"
                      icon={expandOutline}
                      style={{width: 24, height: 24, color: "#000000"}}
                  >
                  </IonIcon>
                </IonButton>
              </IonButtons>
          }

        </IonToolbar>
      </Wrapper>

      <IonLoading message={'Loading Auction Data...'} isOpen={!activeStateItem}/>

      {(activeStateItem && !list) &&
          <IonCard ref={animationRef} style={{height: '90%'}}>
            <IonCardHeader class="ion-text-center">
              <IonCardTitle style={{color: 'black', fontSize: 45}}>
                {location ? location + ' items' : 'All Items'}
              </IonCardTitle>
            </IonCardHeader>
            <IonCardContent style={{marginLeft: 25}}>
              <IonGrid>
                <IonRow>
                  <IonCol size={'4'}>
                    <IonImg className="card-image" src={activeStateItem?.imageurl} />
                  </IonCol>
                  <IonCol size={'1'}>

                  </IonCol>
                  <IonCol size={'7'}>
                    <IonRow>
                      <IonCardHeader  class="ion-text-left" style={{ paddingTop: 0}}>
                        <IonCardTitle style={{color: 'black', fontSize: 32}}>
                          {activeStateItem?.name}
                        </IonCardTitle>
                        {(activeStateItem && activeStateItem?.donatedby && activeStateItem?.donatedby.length > 0) &&
                            <IonCardTitle style={{color: 'gray', fontSize: 16, paddingTop: 10, paddingLeft: 10}}>
                              Donated By: {activeStateItem?.donatedby}
                            </IonCardTitle>
                        }
                        {/*<IonCardSubtitle style={{color: 'gray', fontSize: 22, paddingTop: 10, paddingLeft: 10, display: '-webkit-box; -webkit-line-clamp: 5; -webkit-box-orient: vertical',*/}
                        {/*  overflow: 'hidden', whiteSpace: 'break-spaces', maxHeight: 175}}>*/}
                        <IonCardTitle style={{color: 'gray', fontSize: 22, paddingTop: 10, paddingLeft: 10}}>
                          {activeStateItem?.description}
                        </IonCardTitle>
                      </IonCardHeader>
                    </IonRow>

                    <IonCardHeader class="ion-text-left" >
                      <IonRow>

                        <IonCol size={'6'}>
                          <IonCardTitle style={{color: 'black', fontSize: 32}}>
                            {getStatusText(activeStateItem)}
                          </IonCardTitle>
                        </IonCol>
                        <IonCol size={'3'} style={{paddingTop: 20}} class="ion-text-center">
                          <IonCardTitle style={{color: 'black', fontSize: 18}}>
                            {Number(activeStateItem?.buyitnowprice) > 0 &&
                                currencyFormat(Number(activeStateItem?.buyitnowprice))
                            }
                          </IonCardTitle>
                        </IonCol>
                        <IonCol size={'3'} style={{paddingTop: 20}} class="ion-text-center">
                          <IonCardTitle style={{color: 'black', fontSize: 18}}>
                            {Number(activeStateItem?.fairmarketvalue) > 0 &&
                                currencyFormat(Number(activeStateItem?.fairmarketvalue))
                            }
                          </IonCardTitle>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size={'6'} style={{paddingTop: 5}} >
                          {activeStateItem?.quantityavailable > 0 ?
                              <>
                                <IonCardTitle style={{color: 'gray', fontSize: 18}}>{activeStateItem?.numberofbids} Bids</IonCardTitle>
                                <IonCardTitle style={{color: 'gray', fontSize: 18}}>{activeStateItem?.quantityavailable} Available</IonCardTitle>
                              </>
                              :
                              <>
                                <IonCardTitle style={{color: 'gray', fontSize: 22}}>{getHighBidderText(activeStateItem?.highbidder)}</IonCardTitle>
                              </>
                          }
                        </IonCol>
                        <IonCol size={'3'} style={{paddingTop: 5}} class="ion-text-center">
                          {Number(activeStateItem?.buyitnowprice) > 0 &&
                              <IonCardTitle style={{color: 'gray', fontSize: 18}}>Buy It Now</IonCardTitle>
                          }
                        </IonCol>
                        <IonCol size={'3'} style={{paddingTop: 5}} class="ion-text-center">
                          {Number(activeStateItem?.fairmarketvalue) > 0 &&
                              <IonCardTitle style={{color: 'gray', fontSize: 18}}>Fair Market Value</IonCardTitle>
                          }
                        </IonCol>
                      </IonRow>
                      {activeStateItem?.quantityavailable > 0 &&
                          <IonRow>
                            <IonCol size={'12'} style={{paddingTop: 15}} >
                              <IonCardTitle style={{color: 'gray', fontSize: 22}}>{getHighBidderText(activeStateItem?.highbidder)}</IonCardTitle>
                            </IonCol>
                          </IonRow>
                      }
                    </IonCardHeader>
                    <IonCardHeader class="ion-text-left">
                      <IonCardTitle style={{color: 'black', fontSize: 32}}>
                        {message}
                      </IonCardTitle>
                    </IonCardHeader>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
      }
      {(activeStateItem && list) &&
          <>
            <SlideshowDetailPage animationRef={animationRef} message={lstMessage} location={location}/>
          </>
      }
      </FullScreen>
    </>
  )
}

export default SlideshowPage
