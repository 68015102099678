import "./iframe.css"

import React, { useEffect, useState } from "react"
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from "react-redux"
import {IonBackdrop, IonLoading, IonModal} from "@ionic/react";
import styled from "@emotion/styled"
import {IonCol, IonGrid, IonRow, IonToast} from "@ionic/react"

import CheckBox from "../../components/CheckBox/Index"
import {
  fetchCartData, resetStatus, resetTotalAmount,
  sendCheckoutData, setAllAuctionsChecked, setAuctionsWonCheckedItem, updateTotalAmount
} from "../../redux/slices/cartSlice"
import {resetOrderStatus} from "../../redux/slices/ordersSlice";
import { CART_ENDPOINT, getEndPoint } from "../../util/api/enpoints"
import ButtonItem from "../CommonStyles/ButtonItem"
import Text from "../CommonStyles/Text"
import {useHistory, useParams} from "react-router";
import waveBluePng from "../../assets/img/svg/wave-blue.png";

const EventNameContainer = styled.div`
  background-color: #b6d9ee;
  width: 100%;
  min-height: 45px;
`
const WaveContainer = styled.div`
  background: url(${waveBluePng}) no-repeat;
  width: 100%;
  min-height: 45px;
  background-size: 100% 100%;
`

const CheckoutContainer = styled.div`
  //height: 100%;
  // margin-top:50px;
  width: 100%;
  overflow-y: auto;
  padding: 0 5px;
\`
`
type CehckoutPopupProps = {
  open: boolean
}

const Span = styled.span`
  padding-left: 0.5em;
`

const SpanImage = styled.span`
  margin-top: 5px;
`

const Image = styled.img`
  height: 30px;
`

const Input = styled("input")`
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: #0000000d;
  outline: none;
  border: none;
  padding-left: 15px;
  ::placeholder {
    font-size: 14px;
    padding-left: 0px;
  }
  width: ${(props) => props.width || "100%"};
`

const ModalContent = styled.div`
  margin-top: 32px;
  margin-left: 32px;
  margin-right: 32px;
  height: 32em;
  text-align: left;
  h1 {
    font-family: var(--ion-font-family);
    font-size: 24px/33px;
    font-weight: bold;
  }
  p {
    font-family: var(--ion-font-family);
  }
  letter-spacing: 0px;
  color: #333333;
`

const BuyItNowContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #ffffff;
  border-radius: 30px 30px 0 0;
`
const CheckoutPopup = styled.div<CehckoutPopupProps>`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #f2f2f2;
  height: ${(props) => (props.open ? "300px" : 0)};
  transition-duration: 1s;
  transition-property: height;
  border-radius: 30px 30px 0 0;
`

type ItemWonList = {
  itemid: number
  historyid: number
  name: string
  description: string
  itemnum: string
  quantity: number
  amount: number
  location: string
  pickinstructions: string
  checked: boolean
}

const GoEmerchant = () => {
  return (
        <div >
          <SpanImage id="goe-logo">Payment securely processed by: <a href="https://goemerchant.com" target="_blank">
            <Image alt="goEmerchant Payment Processing" src="https://donorsnap.com/graphics/entry/goe-logo.png"></Image>
          </a></SpanImage>
        </div>
  )
}

interface Params {
  eventcode: string
  orders: string
  historyid: string
}

const Checkout = () => {
  const params = useParams<Params>();
  const dispatch = useDispatch()
  const history = useHistory()

  const [selectedItems, setSelectedItems] = useState([])
  const cartState = useSelector((state: any) => state.cart)
  const eventState = useSelector((state: any) => state.event)
  const [cryptogram, setCryptogram] = useState('');

  useEffect(() => {
    if (cartState.status === "idle") {
      dispatch(fetchCartData(getEndPoint(CART_ENDPOINT)))
    }

    return () => {
      dispatch(setAllAuctionsChecked())
    }
  }, [])

  useEffect(() => {
    var cartSelectedItem = []
    if (Number(params.historyid) > 0) {
      cartSelectedItem = cartState.auctionswon?.filter(
          (item: any) => Number(item.historyid) === Number(params.historyid)
      )
    }
    else if (cartState.auctionsWonChecked && cartState.auctionsWonChecked?.length > 0) {
      cartSelectedItem = cartState.auctionsWonChecked?.filter(
          (item: any) => item.checked
      )
    }
    else if (params.orders === '1')  {
      const storageItems = localStorage.getItem('cartitems')
      if (storageItems) {
        const cartItems = JSON.parse(storageItems);
        if (cartItems && cartItems.length > 0) {
          console.log('processed cart')
          cartSelectedItem = cartItems
        }
      }
    }

    setSelectedItems(cartSelectedItem)
  }, [cartState])


  const calcAmountWon = (itemsWon: any) => {
    return itemsWon?.reduce((acc: any, curr: any) => {
      return acc + curr.amount * curr.quantity
    }, 0)
  }

  const calcQuantity = (itemsWon: any) => {
    return itemsWon?.reduce((acc: any, curr: any) => {
      return acc + curr.quantity
    }, 0)
  }

  const totalWonAmount = calcAmountWon(selectedItems)

  const getItems = (selItems: any) => {
    return selItems.map((item: ItemWonList) => {
      return (
          <div>
            <IonRow>
              <IonCol size="9">
                <label>
                  <Text fontSize="14px" fontWeight="800">
                    <Span>{item.name}</Span>
                  </Text>
                </label>
              </IonCol>
              <IonCol size="3">
                <Text fontWeight="800" fontSize="20px">
                  ${(Math.round(item.amount * 100) / 100).toFixed(2)}
                </Text>
              </IonCol>
            </IonRow>
            <IonRow style={{ paddingLeft: "35px" }}>
              <IonCol
                  className="ion-no-padding"
                  style={{ borderBottom: "1px solid #dadada" }}
              >
                <Text fontSize="14px" color="#a2a2a2">
                  {(item.itemnum && item.itemnum.length > 0) && '# ' + item.itemnum + ', Qty ' + item.quantity}
                </Text>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="9">
                <label>
                  <Text fontSize="14px" fontWeight="800">
                    <Span></Span>
                  </Text>
                </label>
              </IonCol>
              <IonCol size="3">
                <Text fontWeight="800" fontSize="20px">
                </Text>
              </IonCol>
            </IonRow>
            <IonRow style={{ paddingLeft: "35px" }}>
              <IonCol
                  className="ion-no-padding"
              >
                <Text fontSize="14px" color="#a2a2a2">
                </Text>
              </IonCol>
            </IonRow>
          </div>
        )
    });

  }

  const [paymentProcessFeesCheckbox, setPaymentProcessFeesCheckbox] = useState(
    false
  )
  const [isLoading, setIsLoading] = useState(
      false
  )

  const [additionalDonation, setAdditionalDonation] = useState<string | number>(
    ""
  )

  const [modalState, setModalState] = useState({
    display: false,
    title: '',
    message: '',
    goBack: false
  })

  const [toastState, setToastState] = useState({
    display: false,
    goback: false,
    message: ''
  })

  const [orderTotal, setOrderTotal] = useState(0)
  const [paymentMethod, setPaymentMethod] = useState("creditCard")

  // useEffect(() => {
  //   setCryptogram('');
  //   if (cartState.status === "idle") {
  //     dispatch(fetchCartData(getEndPoint(CART_ENDPOINT)))
  //   }
  // }, [cartState.status])

  // useEffect(() => {
  //   if (cartState.auctionswon && cartState.auctionswon.length) {
  //     if (cartState.auctionsWonChecked === null) {
  //       dispatch(setInitialAuctionsWonChecked())
  //     }
  //   }
  // }, [cartState, dispatch])

  useEffect(() => {
    const donation =
      additionalDonation === "" ? 0 : parseFloat(additionalDonation.toString())
    if (paymentProcessFeesCheckbox) {
      const ccfee = cartState.ccfeespercentage / 100
      setOrderTotal(
          totalWonAmount + donation + (ccfee * (totalWonAmount + donation))
      )
    } else {
      setOrderTotal(totalWonAmount + donation)
    }
  }, [
    cartState.auctionsWonChecked,
    cartState.ccfeespercentage,
    additionalDonation,
    totalWonAmount,
    paymentProcessFeesCheckbox
  ])

  useEffect(() => {
    const script = document.createElement("script")

    script.src = eventState.javascriptfile
    script.id =  "firstpay-script-cryptogram"
    script.type = "text/javascript"

    script.setAttribute("data-transcenter", eventState.transcenterid)
    script.setAttribute("data-processor", eventState.processorid)

    document.body.appendChild(script)
  }, [eventState])

  const changePaymentMethod = (e: any) => {
    setPaymentMethod(e.target.value)
  }

  const addAdditionalDonation = (e: any) => {
     if(e.target.value < 0){
        return
    }
    const donation = e.target.value
    setAdditionalDonation(donation)
    /**
     * How do we calculate this question
     */
    const addDonation = e.target.value === "" ? 0 : parseFloat(e.target.value)
    if (paymentProcessFeesCheckbox) {
      const ccfee = cartState.ccfeespercentage / 100
      const total = totalWonAmount + addDonation + ccfee * totalWonAmount
      setOrderTotal(
        total
      )
    } else {
      setOrderTotal(totalWonAmount + addDonation)
    }
  }

  const updateState = () => {
    dispatch(resetStatus())
    dispatch(resetOrderStatus())
    dispatch(fetchCartData(getEndPoint(CART_ENDPOINT)))
  }
  const clickCheckout = async () => {
    setIsLoading(true)
    if (!cryptogram || cryptogram.length <= 0) {
      setIsLoading(false)
      return setToastState({ display: true, goback: false, message: `Please enter a valid credit card.`})
    }
    else {
      const itemIds = selectedItems.map((item: any) => item.itemid)
      const historyids = selectedItems.map((item: any) => item.historyid)
      const checkoutObj = {
        historyid: historyids,
        itemid: itemIds,
        ccfeesexactamount: paymentProcessFeesCheckbox ? Number((totalWonAmount + Number(additionalDonation)) * (cartState.ccfeespercentage / 100) * 100) / 100 : 0,
        additionaldonation: Number(additionalDonation),
        totalamount: orderTotal,
        emerchtoken: cryptogram
      }
      const response: any = await dispatch(sendCheckoutData(checkoutObj))
      const endpointMessage = JSON.parse(response.payload.jsonString)
      let title = 'An error has occurred!'
      let message = endpointMessage.message
      setIsLoading(false)
      if (endpointMessage.status === 'success') {
        localStorage.removeItem('cartitems')
        title = 'Thank you for your purchase!'
        message = "You can view the pickup instructions in your order history!"
      }
      // return setToastState({ display: true, goback: true, message: message})
      setModalState({
        display: true,
        title: title,
        message: message,
        goBack: true
      })

      updateState()
      setTimeout(() => {
        setModalState({
          display: false,
          title: '',
          message: '',
          goBack: false
        })

        if (endpointMessage.status === 'success') {
          if (params.orders === '1') {
            history.push('/' + params.eventcode + '/my-orders')
          } else {
            history.goBack()
          }
        }
      }, 3000)
    }
  }

  useEffect(() => {
    window.addEventListener("message", processPayment, false)
    return () => {
      window.removeEventListener("message", processPayment)
    }
  }, [])

  const processPayment = (e: any) => {
    if (e.data.firstpay) {
      const ccData = e.data
      if (ccData.type === 'newCryptogram') {
        setCryptogram(ccData.cryptogram)
      }
    }
  }
 
  return (
      <CheckoutContainer>
        <EventNameContainer>
          <Text
              fontSize="35px"
              fontWeight="bold"
              textAlign="center"
              padding="24px 0 16px 0"
          >
            Checkout
          </Text>
        </EventNameContainer>
        <WaveContainer></WaveContainer>
        <IonLoading isOpen={isLoading}/>
        <IonModal isOpen={modalState.display} className="custom-message">
          <IonBackdrop />
          <ModalContent>
            <h1>{modalState.title}</h1>
            <p>{modalState.message}</p>
          </ModalContent>
        </IonModal>
        <IonToast
            isOpen={toastState.display}
            onDidDismiss={() => {
              if (toastState.goback) {
                history.goBack()
              }
              setToastState({
                display: false,
                goback: false,
                message: ''
              })
            }}
            cssClass={'custom-toast'}
            message={toastState.message}
            duration={3000}
        />
        <Helmet>
          <link rel="stylesheet" href="/assets/goEmerchant.css" />
        </Helmet>
        {selectedItems && selectedItems.length > 0 && (
            <div>
            <IonGrid>
              <IonRow className="ion-justify-content-center">
                <IonCol>
                  <div className="ion-text-center">
                    <Text fontWeight="800" fontSize="14px" color="#a2a2a2">
                      Items Won ({selectedItems[0].quantity})
                    </Text>
                    <Text fontSize="14px" fontWeight="800" padding="0 0 0 10px">
                      ${(Math.round(calcAmountWon(selectedItems) * 100) / 100).toFixed(2)}
                    </Text>
                  </div>
                </IonCol>
              </IonRow>

              {getItems(selectedItems)}

              {/*{selectedItems.map((item: ItemWonList) => {
                return (
                  <IonGrid key={item.itemid}>
                    <IonRow>
                      <IonCol size="9">
                        <label>
                          <Text fontSize="14px" fontWeight="800">
                            <Span>{item.name}</Span>
                          </Text>
                        </label>
                      </IonCol>
                      <IonCol size="3">
                        <Text fontWeight="800" fontSize="20px">
                          ${(Math.round(item.amount * 100) / 100).toFixed(2)}
                        </Text>
                      </IonCol>
                    </IonRow>
                    <IonRow style={{ paddingLeft: "35px" }}>
                      <IonCol
                        className="ion-no-padding"
                        style={{ borderBottom: "1px solid #dadada" }}
                      >
                        <Text fontSize="14px" color="#a2a2a2">
                          #{item.itemnum}, Qty {item.quantity}
                        </Text>
                      </IonCol>
                    </IonRow>

                    <IonRow>
                      <IonCol size="9">
                        <label>
                          <Text fontSize="14px" fontWeight="800">
                            <Span></Span>
                          </Text>
                        </label>
                      </IonCol>
                      <IonCol size="3">
                        <Text fontWeight="800" fontSize="20px">
                        </Text>
                      </IonCol>
                    </IonRow>
                    <IonRow style={{ paddingLeft: "35px" }}>
                      <IonCol
                          className="ion-no-padding"
                          style={{ borderBottom: "1px solid #dadada" }}
                      >
                        <Text fontSize="14px" color="#a2a2a2">
                        </Text>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                )
              })}
*/}
              <IonRow>
                  <IonCol>
                    <label>Payment Method</label>
                  </IonCol>
              </IonRow>
              <IonRow style={{ paddingLeft: "15px", marginTop: "10px" }}>
                <IonCol>
                  <GoEmerchant/>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <iframe
                    title="1stpayment-iframe"
                    id="firstpay-iframe"
                    src={ eventState.creditcardiframe }
                    data-transcenter-id={ eventState.transcenterid }
                    data-processor-id={ eventState.processorid }
                    data-transaction-type={ eventState.transactiontype }
                    data-manual-submit="false"
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Text fontSize="16px" color="#666666">
                    Additional Donation
                  </Text>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Input
                    type="number"
                    placeholder="Additional Donations"
                    value={String(additionalDonation)}
                    onChange={addAdditionalDonation}
                  />
                </IonCol>
              </IonRow>
              {cartState.showccfees &&
                  <IonRow>
                    <IonCol size="1">
                      <label>
                        <CheckBox
                            name="item"
                            checked={paymentProcessFeesCheckbox}
                            handleCheckboxChange={(e: any) =>
                                setPaymentProcessFeesCheckbox(e.target.checked)
                            }
                            param="item"
                            checkedBackgroundColor="#5732db"
                            uncheckedBackgroundColor="white"
                            height="24px"
                            width="24px"
                            borderRadius="5px"
                        >
                        </CheckBox>
                      </label>
                    </IonCol>
                    <IonCol size="9">
                      <Span>Add {(Math.round(cartState.ccfeespercentage * 100) / 100).toFixed(2)}% to cover payment processing fees</Span>
                    </IonCol>
                  </IonRow>
              }

            <BuyItNowContainer>
              <IonRow><IonCol></IonCol><IonCol></IonCol></IonRow>
                {selectedItems &&
                    <IonRow className="ion-justify-content-between">
                      <IonCol>
                        <div className="ion-text-left">
                          <Text fontSize="12px" color="#a2a2a2">
                            Subtotal ({calcQuantity(selectedItems)} items)
                          </Text>
                        </div>
                      </IonCol>
                      <IonCol>
                        <div className="ion-text-right">
                          <Text fontSize="14px">${(Math.round(calcAmountWon(selectedItems) * 100) / 100).toFixed(2)}</Text>
                        </div>
                      </IonCol>
                    </IonRow>
                }

                <IonRow className="ion-justify-content-between">
                  <IonCol>
                    <Text fontSize="12px" color="#a2a2a2">
                      Additional Donations
                    </Text>
                  </IonCol>
                  <IonCol>
                    <div className="ion-text-right">
                      <Text fontSize="14px">
                        ${additionalDonation === "" ? 0 : ((Number(additionalDonation) * 100) / 100).toFixed(2)}
                      </Text>
                    </div>
                  </IonCol>
                </IonRow>
              {cartState.showccfees &&
                  <IonRow className="ion-justify-content-between">
                    <IonCol>
                      <Text fontSize="12px" color="#a2a2a2">
                        CC Fees
                      </Text>
                    </IonCol>
                    <IonCol>
                      <div className="ion-text-right">
                        <Text fontSize="14px">
                          $
                          {paymentProcessFeesCheckbox &&
                              (Number((totalWonAmount + Number(additionalDonation)) * (cartState.ccfeespercentage / 100) * 100) / 100).toFixed(2)}
                          {paymentProcessFeesCheckbox === false && 0}
                        </Text>
                      </div>
                    </IonCol>
                  </IonRow>
              }
                <IonRow className="ion-justify-content-between">
                  <IonCol>
                    <Text fontSize="14px" color="#a2a2a2" fontWeight="600">
                      Order Total
                    </Text>
                  </IonCol>
                  <IonCol>
                    <div className="ion-text-right">
                      <Text fontSize="14px">${ ((orderTotal * 100) / 100).toFixed(2) } </Text>
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow onClick={clickCheckout}>
                  <IonCol>
                    <ButtonItem height="45px">Checkout</ButtonItem>
                  </IonCol>
                </IonRow>
            </BuyItNowContainer>
          </IonGrid>
          <CheckoutPopup open={false}> </CheckoutPopup>
          </div>
        )}
      </CheckoutContainer>
  )
}

export default Checkout
