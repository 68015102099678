import React, {useEffect, useRef, useState} from "react";
import {
    IonBackdrop,
    IonButton,
    IonCol,
    IonContent, IonGrid,
    IonHeader, IonInput,
    IonItem,
    IonLabel, IonModal, IonPage,
    IonRow,
    IonTabButton, IonToast,
    IonToolbar,
    IonButtons, IonTitle, useIonModal, IonFooter
} from "@ionic/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Text from "../CommonStyles/Text";
import "./iframe.css"
import axios from "axios";
import styled from "@emotion/styled";
import './index.css';
import {useHistory} from "react-router";
import CheckBox from "../../components/CheckBox/Index"
import {OverlayEventDetail} from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import {formatMoney} from "../../util/formatHelpers";
import {usStates} from "../../constants";
import {car} from "ionicons/icons";
import CurrencyInput from "react-currency-input-field";
import RegistrationPage from "./RegistrationPage";
import {getConfig} from "../../AppConfig";

// function ModalContent(props: { children: ReactNode }) {
//     return null;
// }

const Span = styled.span`
  padding-left: 0.5em;
`

const SpanImage = styled.span`
  margin-top: 5px;
`

const Image = styled.img`
  height: 30px;
`


export const Input = styled("input")`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: #e0ebf180;
  outline: none;
  border: 1px solid #66666660;
  padding-left: 15px;
  ::placeholder {
    font-size: 14px;
    padding-left: 0px;
  }
`;

const Select = styled("select")`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: #e0ebf180;
  outline: none;
  border: 1px solid #66666660;
  padding-left: 15px;
  ::placeholder {
    font-size: 14px;
    padding-left: 0px;
  }
`

const Option = styled("option")`
  box-sizing: border-box;
  background-color: #0000000d;
  outline: none;
  border: none;
  ::placeholder {
    font-size: 14px;
    padding-left: 4px;
  }
`

const ModalContent = styled.div`
      margin-top: 32px;
      margin-left: 32px;
      margin-right: 32px;
      height: 32em;
      text-align: left;
      h1 {
        font-family: var(--ion-font-family);
        font-size: 33px;
        font-weight: bold;
        text-align: center;
      }
      h2 {
        font-family: var(--ion-font-family);
        font-size: 26px;
        //font-weight: bold;
        text-align: center;
      }
      p {
        font-family: var(--ion-font-family);
      }
      letter-spacing: 0px;
      color: #333333;
    `


const MessageModal = ({
                          onDismiss,
                          message,
                      }: {
    onDismiss: (data?: string | null | undefined | number, role?: string) => void;
    message: string;
}) => {
    const inputRef = useRef<HTMLIonInputElement>(null);
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Complete Registration</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonItem>
                    <IonLabel class={'ion-text-wrap'} style={{ fontSize: '18px'}}>{message}</IonLabel>
                </IonItem>
            </IonContent>
            <IonFooter>
                <IonRow style={{marginBottom: '20px'}}>
                    <IonCol style={{display: 'flex'}} className="ion-justify-content-center">
                        <IonButton onClick={() => onDismiss(inputRef.current?.value, 'cancel')} size="large" style={{ width: '80%' }} expand="block" fill="solid" color={'primary'}>
                            Cancel
                        </IonButton>
                    </IonCol>
                    <IonCol style={{display: 'flex'}} className="ion-justify-content-center">
                        <IonButton onClick={() => onDismiss(inputRef.current?.value, 'ok')} size="large" style={{ width: '80%' }} expand="block" fill="solid" color={'primary'}>
                            Ok
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonFooter>
        </IonPage>
    );
};

const RegisterPage = ({storeData, setStoreData, selectedTab, setSelectedTab, eventcode, auctioncode}) => {
    const [message, setMessage] = useState('You will not be able to return to this screen to add/edit your guests. Click ok to submit your final registration. If you have any changes to make afterwards, contact the event organizer.');
    const [present, dismiss] = useIonModal(MessageModal, {
        onDismiss: (data: string, role: string) => dismiss(data, role),
        message: message,
    });

    function openRegistrationModal() {
        present({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
                if (ev.detail.role === 'ok') {
                    localStorage.removeItem("tokenstore")
                    history.push(`/${eventcode}/event/`);
                }
            },
        });
    }

    function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
        if (ev.detail.role === 'confirm') {
            console.log('confirm')
        }
        setQuantityModal({active: false, quantity: 0, item:''})
    }

    const [itemQuantity, setItemQuantity] = React.useState({})
    const [itemPrice, setItemPrice] = React.useState({})
    const [cryptogram, setCryptogram] = useState('')
    const [paymentProcessFeesCheckbox, setPaymentProcessFeesCheckbox] = useState(false)
    const [toastState, setToastState] = useState({display: false, goback: false, message: ''})
    const [isLoading, setIsLoading] = useState(false)
    const [additionalDonation, setAdditionalDonation] = useState<number>(0)
    const [promoCodeAmount, setPromoCodeAmount] = useState<number>(0)
    const [promoCode, setPromoCode] = useState<string>("")
    const [orderTotal, setOrderTotal] = useState(0)
    const [subtotalTotal, setSubtotalTotal] = useState(0)
    const [ccTotal, setCCTotal] = useState(0)
    const [ccTotalFee, setCCTotalFee] = useState(0)
    const [finishCheckout, setFinishCheckout] = useState(false)

    const [firstname, setFirstName] = useState<string>("")
    const [lastname, setLastName] = useState<string>("")
    const [address1, setAddress1] = useState<string>("")
    const [city, setCity] = useState<string>("")
    const [state, setState] = useState<string>("AL")
    const [zip, setZip] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [paymentmethod, setPaymentMethod] = useState<string>("CREDIT")

    const [cartData, setCartData] = React.useState({
        cartitems: [],
        allowpaymentoffees: false,
        hascheckedout: false,
        billme: false,
        ccfee: 0,
        creditcardiframe: 'https://secure.1stpaygateway.net/secure/PaymentHostedForm/v3/CreditCard',
        discountamount: 0,
        discountcode: '',
        hasdiscount: true,
        javascriptfile: 'https://secure.1stpaygateway.net/secure/PaymentHostedForm/Scripts/firstpay/firstpay.cryptogram.js',
        message: '',
        processorid: "70802",
        transactiontype: "Sale",
        transcenterid: "92754",
        first: "",
        last: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        email: "",
        paymentmethod: "",
        optionaltotal: 0,
        subtotal: 0,
        totalamount: 0,
        additionaldonation: 0
    })

    const getStoreSetup = async () => {
        setStoreData({
            storewelcomeeventname: '',
            storewelcomeheader: '',
            storewelcomeeventlogourl: '',
            storewelcomebuttonevent: '',
            storewelcomebuttonauction: '',
            storewelcomebuttoneventshow: true,
            storewelcomebuttonauctionshow: true,
            torewelcomefooter: '',
            storewelcomeauctionlink: '',
            tabs: [],
            token: '',
            hascheckedout: false
        })

        const body = {auctioncode: auctioncode, eventcode: eventcode}
        const token = localStorage.getItem('tokenstore')

        const headers = token  ? {'Authorization': `Bearer ${token}`} : {}
        let url = `${apiUrl}/storesetup`
        const {data} = await axios.post(url, body, {headers: headers})
        const parsedJson = JSON.parse(data?.jsonInfo)

        if (parsedJson.status === 'expired') {
            localStorage.removeItem("tokenstore")
            history.push(`/${eventcode}/event`);
        }
        else if (parsedJson.token) {
            setStoreData(parsedJson)
            localStorage.setItem('tokenstore', parsedJson.token)
        }
        else {
            setStoreData(parsedJson)
        }
    }

    const [quantityModal, setQuantityModal] = React.useState({active: false, quantity: 0, item: ""})
    const [maxQuantityPerTransModel, setMaxQuantityPerTransModal] = React.useState({active: false, maxquantity: 0, name: ""})

    const { apiUrl } = getConfig()
    const history = useHistory()
    const storeCartAddItemUrl = `${apiUrl}/storecartadditem`
    const cartItemUrl = `${apiUrl}/storecart`
    const storecartdiscount = `${apiUrl}/storecartdiscount`
    const checkoutUrl = `${apiUrl}/storecheckout`
    const storeguestUrl = `${apiUrl}/storeguest`
    const checkoutTab = 'Checkout'

    useEffect(() => {
        const quantity = {...itemQuantity}
        const prices = {...itemPrice}
        storeData?.tabs?.map((item, index) => {
            item.sections.map(section => {
                section.data.map(itemdata => {
                    if (itemdata && itemdata.id > 0) {
                        quantity[itemdata.id] = Number(itemdata.quantity)
                        prices[itemdata.id] =  '$' + itemdata.price * Number(itemdata.quantity)
                    }
                })
            })
        })
        setItemQuantity(quantity)
        setItemPrice(prices)
    }, [storeData])


    useEffect(() => {
        const donation = Number(additionalDonation)
        const discount = Number(promoCodeAmount)
        const subtotal = Number(subtotalTotal)
        const ccfee = cartData.ccfee / 100
        const ccTotalFee = (ccfee * (subtotal + donation - discount))
        setCCTotal(ccfee)
        setCCTotalFee(ccTotalFee)
        if (paymentProcessFeesCheckbox) {
            setOrderTotal(
                subtotal + donation + ccTotalFee - discount
            )
        }
        else {
            setCCTotal(0)
            setOrderTotal(subtotal + donation - discount)
        }
    }, [
        additionalDonation,
        paymentProcessFeesCheckbox,
        subtotalTotal,
        promoCodeAmount
    ])


    useEffect(() => {
        if (selectedTab === 'Cart') {
            getCartItems()
        }
        // console.log(selectedTab)
        // const sectionDisplay = getSectionData(selectedTab)
        // console.log(sectionDisplay)
    }, [selectedTab])

    useEffect(() => {
        const script = document.createElement("script")

        script.src = cartData?.javascriptfile
        script.id =  "firstpay-script-cryptogram"
        script.type = "text/javascript"

        script.setAttribute("data-transcenter", cartData?.transcenterid)
        script.setAttribute("data-processor", cartData?.processorid)

        document.body.appendChild(script)

        var subtotal = 0
        cartData?.cartitems?.map((item) => {
            subtotal += item.lineamount
        })
        setSubtotalTotal(subtotal)

        if (cartData) {
            setFirstName(cartData.first)
            setLastName(cartData.last)
            setAddress1(cartData.address)
            setCity(cartData.city)
            setState(cartData.state)
            setZip(cartData.zip)
            setEmail(cartData.email)
            if (cartData && cartData?.paymentmethod && cartData?.paymentmethod !== "0") {
                console.log('setting up payment method - ' + cartData?.paymentmethod)
                setPaymentMethod(cartData.paymentmethod)
            }
            else {
                console.log('setting up payment method - CREDIT')
                setPaymentMethod('CREDIT')
            }

            setPromoCode(cartData.discountcode)
            setPromoCodeAmount(cartData.discountamount)
            setAdditionalDonation(cartData.additionaldonation)
        }
    }, [cartData])

    useEffect(() => {
        setPaymentMethod('CREDIT')

        window.addEventListener("message", processPayment, false)
        return () => {
            window.removeEventListener("message", processPayment)
        }
    }, [])

    const processPayment = (e: any) => {
        if (e.data.firstpay) {
            const ccData = e.data
            if (ccData.type === 'newCryptogram') {
                setCryptogram(ccData.cryptogram)
            }
        }
    }

    const clickCheckout = async () => {
        setIsLoading(true)

        if (paymentmethod === "CREDIT" && (!cryptogram || cryptogram.length <= 0)) {
            setIsLoading(false)
            return setToastState({ display: true, goback: false, message: `Please enter a valid credit card.`})
        }
        else if (!firstname) {
            setIsLoading(false)
            return setToastState({ display: true, goback: false, message: `First name is required for checkout.`})
        }
        else if (!lastname) {
            setIsLoading(false)
            return setToastState({ display: true, goback: false, message: `Last name is required for checkout.`})
        }
        else if (!zip) {
            setIsLoading(false)
            return setToastState({ display: true, goback: false, message: `Zip is required for checkout.`})
        }
        else {
            const body = {
                "first":firstname,
                "last":lastname,
                "address":address1,
                "city":city,
                "state":state,
                "zip":zip,
                "email":email,
                "subtotal": subtotalTotal,
                "discountamount": promoCodeAmount,
                "additionaldonation": additionalDonation,
                "ccfeesamount": ccTotal,
                "totalamount": orderTotal,
                "paymentmethod": paymentmethod,
                "emerchtoken": cryptogram
            }
            const token = localStorage.getItem('tokenstore')

            const {data} = await axios.put(checkoutUrl, body, {headers: {
                    'Authorization': `Bearer ${token}`
                }})
            const parsedData = JSON.parse(data.jsonString)
            console.log(parsedData)
            if (parsedData.status === 'expired') {
                localStorage.removeItem("tokenstore")
                history.push(`/${eventcode}/event`);
            }
            if (parsedData.status === 'success') {
                if (parsedData && parsedData?.token) {
                    localStorage.setItem('tokenstore', parsedData.token)
                    await getStoreSetup()
                    storeData.tabs.map((tab, index) => {
                        if (tab.tabname === selectedTab) {
                            console.log(storeData.tabs[index + 1].tabname)
                            setSelectedTab(storeData.tabs[index + 1].tabname)
                        }
                    })
                }
                else {
                    // var message = 'Thank you for checking out'
                    // if (parsedData.message) {
                    //     message = parsedData.message
                    // }
                    // setMessageModal({active: true, message: message})
                    // setFinishCheckout(true)
                    goFinish()
                }
            }
        }
    }

    const getCartItems = async () => {
        const body = {}
        const token = localStorage.getItem('tokenstore')

        const {data} = await axios.get(cartItemUrl, {headers: {
                'Authorization': `Bearer ${token}`
            }})
        const parsedData = JSON.parse(data.jsonString)

        console.log(parsedData)
        if (parsedData.status === 'expired') {
            localStorage.removeItem("tokenstore")
            history.push(`/${eventcode}/event`);
        }
        else if (parsedData.status === 'success') {
            setCartData(parsedData)
        }
    }

    const getDiscount = async (discountcode) => {
        const body = {"discountcode": discountcode}
        const token = localStorage.getItem('tokenstore')

        const {data} = await axios.post(storecartdiscount, body, {headers: {
                'Authorization': `Bearer ${token}`
            }})
        const parsedData = JSON.parse(data.jsonString)
        console.log(parsedData)
        if (parsedData.status === 'expired') {
            localStorage.removeItem("tokenstore")
            history.push(`/${eventcode}/event`);
        }
        else if (parsedData.status === 'success') {
            console.log(parsedData.discountamount)
            return parsedData.discountamount
        }
        else {
            return 0
        }
    }

    const validSectionData = (data) => {
        var valid = true
        data.map(item => {
            if (item && item?.description && item?.description?.length > 0) {
                valid = true
            }
            else {
                return false
            }
        })
        return valid
    }

    const quantityUpdate = async (value, item) => {
        // console.log(item)
        // console.log(value)
        // console.log(item.maxquantitypertransaction)
        // console.log(item.price)
        if (item.maxquantitypertransaction >= Number(value) || item.maxquantitypertransaction == 0) {
            const quantity = {...itemQuantity}
            quantity[item.id] = Number(value)
            setItemQuantity(quantity)

            var prices = {...itemPrice}
            prices[item.id] =  '$' + item.price * value
            console.log(item.price * value)
            setItemPrice(prices)
        }
        else {
            setMaxQuantityPerTransModal({active: true, maxquantity: item.maxquantitypertransaction, name: item.name})
        }
    }

    const quantityBlur = async (value, item) => {
        const body = {quantity: Number(value), itemid: Number(item.id)}
        const token = localStorage.getItem('tokenstore')

        if (item?.maxquantitypertransaction < value) {
            const quantity = {...itemQuantity}
            quantity[item.id] = Number(item.maxquantitypertransaction)

            var prices = {...itemPrice}
            prices[item.id] =  '$' + item.price * quantity[item.id]
            setItemPrice(prices)
            setItemQuantity(quantity)
            setMaxQuantityPerTransModal({active: true, maxquantity: item.maxquantitypertransaction, name: item.name})
        }
        else {
            const {data} = await axios.post(storeCartAddItemUrl, body, {headers: {
                    'Authorization': `Bearer ${token}`
                }})
            const parsedData = JSON.parse(data.jsonString)
            if (parsedData.status === 'expired') {
                localStorage.removeItem("tokenstore")
                history.push(`/${eventcode}/event`);
            }
            else if (parsedData.status === 'failure') {
                const quantity = {...itemQuantity}
                quantity[parsedData.itemid] = Number(parsedData.quantityremaining)
                setItemQuantity(quantity)
                setQuantityModal({active: true, quantity: parsedData.quantityremaining, item: parsedData.itemname})
            }
        }
    }

    const calcItemPrice = (item) => {
        // console.log('calcItemPrice')
        // console.log(item.price)
        var quantityValue = itemQuantity[item.id]
        if (item.maxquantitypertransaction > quantityValue) {
            quantityValue = item.maxquantitypertransaction
        }
        const priceUnformatted = Number(itemPrice[item.id].split("$").pop())
        const total = formatMoney(priceUnformatted)
        const price = formatMoney(item.price)

        var totalPrice = (item.id in itemPrice) ? `X ${price} = ${total}` : `X ${price} = $0`

        return totalPrice
    }

    const goBack = () => {
        storeData.tabs.map((tab, index) => {
            if (tab.tabname === selectedTab) {
                setSelectedTab(storeData.tabs[index - 1].tabname)
            }
        })
    }
    const goNext = () => {
        storeData.tabs.map((tab, index) => {
            if (tab.tabname === selectedTab) {
                setSelectedTab(storeData.tabs[index + 1].tabname)
            }
        })
    }

    const saveAndContinue = () => {
        history.push(`/${eventcode}/event/`);
    }

    const goFinish = () => {
        if (selectedTab === 'Registration') {
            openRegistrationModal()
        }
        else {
            localStorage.removeItem("tokenstore")
            history.push(`/${eventcode}/event/`);
        }
    }

    const hasNext = () => {
        var nextBool = false
        storeData?.tabs?.map((tab, index) => {
            if (tab.tabname === selectedTab) {
                if (storeData.tabs.length > (index + 1)) {
                    nextBool = true
                }
            }
        })
        return nextBool
    }

    const hasBack = () => {
        var nextBool = false
        storeData?.tabs?.map((tab, index) => {
            if (tab.tabname === selectedTab) {
                if (index > 0) {
                    nextBool = true
                }
            }
        })
        return nextBool
    }

    const getButtons = () => {
        const cartButton = (
                <IonCol style={{display: 'flex'}} className="ion-justify-content-center ion-col-custom">
                    <IonButton onClick={() => {clickCheckout()}} size="large" style={{ width: '80%' }} expand="block" fill="solid" color={'primary'}>
                        Checkout
                    </IonButton>
                </IonCol>
        )

        const backButton = (
                <IonCol style={{display: 'flex'}} className="ion-justify-content-center ion-col-custom">
                    <IonButton onClick={goBack} size="large" style={{ width: '80%' }} expand="block" fill="solid" color={'primary'}>
                        Back
                    </IonButton>
                </IonCol>
        )
        const nextButton = (
                <IonCol style={{display: 'flex'}} className="ion-justify-content-center ion-col-custom">
                    <IonButton onClick={goNext} size="large" style={{ width: '80%' }} expand="block" fill="solid" color={'primary'}>
                        Next
                    </IonButton>
                </IonCol>
        )


        if (finishCheckout || (cartData?.hascheckedout && !storeData?.tabs.find(item => item.tabname === 'Registration').enabled)) {
            return  (
                <IonRow>
                    <IonCol style={{display: 'flex'}} className="ion-justify-content-center ion-col-custom">
                        <IonButton onClick={goFinish} size="large" style={{ width: '80%' }} expand="block" fill="solid" color={'primary'}>
                            Finish
                        </IonButton>
                    </IonCol>
                </IonRow>
            )
        }
        else if (selectedTab === 'Cart') {
            if (storeData?.hascheckedout) {
                return (
                    <>
                        <IonRow>
                            {nextButton}
                        </IonRow>
                    </>
                )
            }
            else {
                return (
                    <>
                        <IonRow>
                            {backButton}
                            {cartButton}
                        </IonRow>
                    </>
                )
            }
        }
        else if (hasBack() && hasNext()) {
            return (
                <>
                    <IonRow>
                        {backButton}
                        {nextButton}
                    </IonRow>
                </>
            )
        }
        else if (hasNext()) {
            return nextButton
        }
        else {
            return  (
                <IonRow>
                    <IonCol style={{display: 'flex'}} className="ion-justify-content-center ion-col-custom">
                        <IonButton onClick={saveAndContinue} size="large" style={{ width: '80%' }} expand="block" fill="solid" color={'primary'}>
                            Save and Continue Later
                        </IonButton>
                    </IonCol>
                    <IonCol style={{display: 'flex'}} className="ion-justify-content-center ion-col-custom">
                        <IonButton onClick={goFinish} size="large" style={{ width: '80%' }} expand="block" fill="solid" color={'primary'}>
                            Finish
                        </IonButton>
                    </IonCol>
                </IonRow>
            )
        }
    }

    const calcAmountWon = () => {
        return cartData.cartitems.reduce((acc: any, curr: any) => {
            return acc + curr.lineamount
        }, 0)
    }

    const addAdditionalDonation = (value) => {
        if (value.float == undefined) {
            setAdditionalDonation(0)
            return
        }
        else if (value.float < 0) {
            return
        }
        else if (value.float > 100000) {
            setAdditionalDonation(100000)
            return
        }
        const donation = value
        setAdditionalDonation(value.float)
    }

    const getDiscountAmount = async () => {
        const discount = await getDiscount(promoCode)
        setPromoCodeAmount(discount)
    }

    const updatePromoCode =  (e: any) => {
        if(e.target.value < 0){
            return
        }
        setPromoCode(e.target.value)
    }

    const GoEmerchant = () => {
        return (
            <div >
                <SpanImage id="goe-logo">Payment securely processed by: <a href="https://goemerchant.com" target="_blank">
                    <Image alt="goEmerchant Payment Processing" src="https://donorsnap.com/graphics/entry/goe-logo.png"></Image>
                </a></SpanImage>
            </div>
        )
    }

    const getDataItems = (items) => {
        const elems = items?.map((item) => {
            console.log(item)
            if (item && item?.name?.length > 0) {
                return (
                    <>
                        <IonRow class={'ion-justify-content-center ion-align-items-center'}>
                            <IonCol>
                                <IonItem class={'ion-no-padding ion-no-margin'} lines={'none'}>
                                    <IonLabel class={'ion-text-wrap'} style={{fontSize: '16px'}}>

                                        <h2>{item.name}</h2>
                                        <p>{item.description}</p>
                                    </IonLabel>
                                </IonItem>
                            </IonCol>
                            <IonCol class={'ion-justify-content-end'}>
                                <IonItem lines={'none'}>
                                    {(item?.isquantityunlimited || item?.quantityremaining > 0) &&
                                        <>
                                            <Input
                                                type="number"
                                                id=""
                                                min={0}
                                                placeholder=""
                                                name=""
                                                disabled={storeData?.hascheckedout}
                                                onChange={(e) => {quantityUpdate(e.target.value, item)}}
                                                onBlur={(e) => {quantityBlur(e.target.value, item)}}
                                                value={itemQuantity[item.id]}
                                                defaultValue={itemQuantity[item.id] > 0 ? itemQuantity[item.id] : 0}
                                            />
                                        </>

                                    }
                                    {(!item?.isquantityunlimited && item?.quantityremaining <= 0) &&
                                        <IonLabel style={{ marginLeft: '10px', fontSize: '16px'}}>SOLD OUT</IonLabel>
                                    }
                                    {(item?.isquantityunlimited || item?.quantityremaining > 0) &&
                                        <IonLabel style={{ marginLeft: '10px', fontSize: '16px'}}>{calcItemPrice(item)}</IonLabel>
                                    }
                                </IonItem>
                            </IonCol>
                            {/*<IonCol>*/}
                            {/*  <IonItem lines={'none'}>*/}
                            {/*    <IonLabel style={{ fontSize: '16px'}}><b>= </b></IonLabel>*/}
                            {/*  </IonItem>*/}
                            {/*</IonCol>*/}
                        </IonRow>
                    </>
                )
            }
        })
        return (
            <>
                {elems}
            </>
        )
    }

    const getCart = () => {
        var subtotal = 0
        const detailRows = cartData?.cartitems?.map((item) => {
            subtotal += item.lineamount
            return (
                <IonRow class={'ion-justify-content-center ion-align-items-center'}>
                    <IonCol>
                        <IonItem lines={'none'}>
                            <IonLabel style={{ marginLeft: '10px', fontSize: '16px'}}>{item.name}</IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol class={'ion-justify-content-end'}>
                        <IonItem lines={'none'}>
                            <IonLabel class={'ion-text-right'} style={{ marginLeft: '10px', fontSize: '16px'}}>{item.quantity + ' X $' + item.price + ' = $' + item.lineamount }</IonLabel>
                        </IonItem>
                    </IonCol>
                </IonRow>
            )
        })

        return (
            <>
                {detailRows}
                {cartData?.cartitems &&
                    <IonRow class={'ion-justify-content-center ion-align-items-center'} >
                        <IonCol class={'ion-justify-content-end'} >
                            <IonItem lines={'none'} >
                                <IonLabel class={'ion-text-right'} style={{ marginLeft: '10px', fontSize: '16px'}}>{'Subtotal: ' + formatMoney(subtotal) }</IonLabel>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                }

                <IonRow class={'ion-justify-content-center ion-align-items-center'}>
                    <IonCol class={'ion-justify-content-end'} >
                        <IonItem lines={'none'} >
                            <IonLabel class={'ion-text-left'} style={{ marginLeft: '10px', fontSize: '16px'}}>{'Additional Donation'}</IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol class={'ion-justify-content-end'} >
                        <IonItem class={'ion-align-items-end'} lines={'none'} >
                            <div className={'right-align-input'}>
                                <CurrencyInput
                                    // id="input-example"
                                    intlConfig={{ locale: 'en-US', currency: 'USD' }}
                                    name="additionalDonation"
                                    style={{  display: 'flex-end', justifyContent: 'flex-end', boxSizing: 'border-box', height: '60px', borderRadius: '10px',
                                        textAlign: 'right', backgroundColor: "#e0ebf180", outline: 'none', border: "1px solid #66666660", paddingRight: '10px', paddingLeft: '15px'}}
                                    placeholder="Additional Donation"
                                    prefix="$"
                                    autoFocus={false}
                                    defaultValue={0}
                                    decimalsLimit={2}
                                    disabled={cartData?.hascheckedout}
                                    onValueChange={(value, name, values) => {
                                        addAdditionalDonation(values)
                                    }}
                                    value={additionalDonation}
                                />
                            </div>
                        </IonItem>
                    </IonCol>
                </IonRow>
                {cartData?.cartitems &&
                    <IonRow class={'ion-justify-content-center ion-align-items-center'}>
                        <IonCol class={'ion-align-items-end'} >
                            <IonItem lines={'none'} >
                                <Input
                                    id="promo-code"
                                    placeholder="Promo Code"
                                    value={String(promoCode)}
                                    // onBlur={(e) => {
                                    //     getDiscountAmount(e)
                                    // }}
                                    onChange={(e) => {
                                        updatePromoCode(e)
                                    }}
                                    disabled={cartData?.hascheckedout}
                                />
                                <IonButton expand="full" className="ion-color-secondary ion-text-center ion-text-wrap ion-align-items-center ion-wrap"
                                           ion-button fill="clear" onClick={getDiscountAmount}>
                                    <IonLabel class={'ion-text-left'} style={{ fontSize: '16px'}}>{'Apply Promo Code'}</IonLabel>
                                </IonButton>
                            </IonItem>
                        </IonCol>
                        <IonCol >
                            <IonItem class={'ion-align-items-center'} lines={'none'} >
                                <IonLabel class={'ion-text-right'} style={{ fontSize: '16px'}}>{'Promo Code: ' + formatMoney(promoCodeAmount) }</IonLabel>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                }
                {cartData.allowpaymentoffees &&
                    <IonRow class={'ion-justify-content-center ion-align-items-center'}>
                        <IonCol class={'ion-align-items-end'}>
                            <IonItem class={'ion-align-items-end'} lines={'none'} >
                                <div className={'left-align-input'}>
                                    <label>
                                        <CheckBox
                                            name="item"
                                            checked={paymentProcessFeesCheckbox}
                                            handleCheckboxChange={(e: any) =>
                                                setPaymentProcessFeesCheckbox(e.target.checked)
                                            }
                                            param="item"
                                            checkedBackgroundColor="#5732db"
                                            uncheckedBackgroundColor="white"
                                            height="24px"
                                            width="24px"
                                            borderRadius="5px"
                                        >
                                        </CheckBox>
                                    </label>
                                    <Span>Add {(Math.round(cartData.ccfee * 100) / 100).toFixed(2)}% to cover payment processing fees</Span>
                                </div>
                            </IonItem>
                        </IonCol>
                        {paymentProcessFeesCheckbox &&
                            <IonCol class={'ion-justify-content-end'} >
                                <IonItem lines={'none'} >
                                    <IonLabel class={'ion-text-right'} style={{ marginLeft: '10px', fontSize: '16px'}}>{'Processing fees: ' + formatMoney(ccTotalFee) }</IonLabel>
                                </IonItem>
                            </IonCol>
                        }
                    </IonRow>
                }
                <IonRow class={'ion-justify-content-end'} >
                    <IonCol class={'ion-justify-content-end'} >
                        <IonItem lines={'none'} >
                            <IonLabel class={'ion-text-right'} style={{ marginLeft: '10px', fontSize: '16px'}}>{'Total: ' + formatMoney(orderTotal) }</IonLabel>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol class={'ion-justify-content-end'}>
                        <div className={'right-align-input'}>
                            <Input
                                type="text"
                                style={{width: '100%'}}
                                placeholder="First Name"
                                value={firstname}
                                disabled={cartData?.hascheckedout || finishCheckout}
                                required={true}
                                onChange={(e) => {
                                    setFirstName(e.currentTarget.value)
                                }}
                            />
                        </div>
                    </IonCol>
                    <IonCol>
                        <Input
                            type="text"
                            style={{width: '100%'}}
                            placeholder="Last Name"
                            value={lastname}
                            required={true}
                            disabled={cartData?.hascheckedout || finishCheckout}
                            onChange={(e) => {
                                setLastName(e.currentTarget.value)
                            }}
                        />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <div className={'center-align-input'}>
                            <Input
                                type="text"
                                style={{width: '100%'}}
                                placeholder="Address"
                                value={address1}
                                disabled={cartData?.hascheckedout || finishCheckout}
                                onChange={(e) => {
                                    setAddress1(e.currentTarget.value)
                                }}
                            />
                        </div>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol >
                        <div  style={{width: '100%'}} className={'left-align-input'}>
                            <Input
                                type="text"
                                style={{width: '100%'}}
                                placeholder="City"
                                value={city}
                                disabled={cartData?.hascheckedout || finishCheckout}
                                onChange={(e) => {
                                    setCity(e.currentTarget.value)
                                }}
                            />
                        </div>
                    </IonCol>
                    <IonCol size="auto">
                        <div style={{width: '120px'}} className={'right-align-input'}>
                            <Select
                                name="state"
                                id="id"
                                style={{width: '100%'}}
                                value={state}
                                disabled={cartData?.hascheckedout || finishCheckout}
                                onChange={(e) => {
                                    setState(e.currentTarget.value)
                                }}
                                // defaultValue={userData.addressInfo.state}
                            >
                                {usStates.map((stateO, index) => {
                                    return (
                                        // <Option value={state.abbreviation} key={index} selected={}>
                                        <Option value={stateO.abbreviation} key={index}>
                                            {stateO.abbreviation}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </div>
                    </IonCol>
                    <IonCol size="auto">
                        <div style={{width: '160px'}} className={'right-align-input'}>
                            <Input
                                type="text"
                                style={{width: '100%'}}
                                placeholder="Zip"
                                required={true}
                                value={zip}
                                disabled={cartData?.hascheckedout || finishCheckout}
                                onChange={(e) => {
                                    setZip(e.currentTarget.value)
                                }}
                            />
                        </div>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <div style={{width: '100%'}} className={'center-align-input'}>
                            <Input
                                type="text"
                                style={{width: '100%'}}
                                placeholder="Email"
                                value={email}
                                required={true}
                                disabled={cartData?.hascheckedout || finishCheckout}
                                onChange={(e) => {
                                    setEmail(e.currentTarget.value)
                                }}
                            />
                        </div>
                    </IonCol>
                </IonRow>
                {cartData?.billme &&
                    <>
                        <IonRow>
                            <IonCol>
                                <div className={'right-align-input'}>
                                    <Select
                                        name="state"
                                        id="id"
                                        style={{width: '100%'}}
                                        value={paymentmethod}
                                        disabled={cartData?.hascheckedout || finishCheckout}
                                        onChange={(e) => {
                                            setPaymentMethod(e.currentTarget.value)
                                        }}
                                        // defaultValue={userData.addressInfo.state}
                                    >
                                        <Option value={'CREDIT'} key={'cc'} selected={paymentmethod === 'CREDIT'}>
                                            {'Credit / Debit Card'}
                                        </Option>
                                        <Option value={'BILLME'} key={'bm'} selected={paymentmethod === 'BILLME'}>
                                            {'Bill Me'}
                                        </Option>
                                    </Select>
                                </div>
                            </IonCol>
                        </IonRow>
                    </>
                }

                {(paymentmethod === "CREDIT" && !cartData?.hascheckedout) &&
                    <>
                        <IonRow style={{ paddingLeft: "15px", marginTop: "10px" }}>
                            <IonCol>
                                <GoEmerchant/>
                            </IonCol>
                        </IonRow>
                    </>
                }
                {/*{(cartData?.hascheckedout === false) &&*/}
                    <IonRow>
                        <IonCol>
                            <iframe
                                key={9009}
                                title="1stpayment-iframe"
                                id="firstpay-iframe"
                                src={ cartData?.creditcardiframe }
                                data-transcenter-id={ cartData?.transcenterid }
                                data-processor-id={ cartData?.processorid }
                                data-transaction-type={ cartData?.transactiontype }
                                data-manual-submit="false"
                                style={{display: (paymentmethod === "CREDIT" ? 'inline' : 'none')}}
                            />
                        </IonCol>
                    </IonRow>
                {/*}*/}
            </>
        )
    }

    // const getSectionData = (currentSel) => {
    //     storeData?.tabs.forEach((item, index) => {
    //         if (currentSel === 'Cart') {
    //             return (
    //                 <>
    //                     {item?.heading?.length > 0 &&
    //                         <IonRow>
    //                             <IonLabel style={{marginLeft: '15px', marginTop: '10px', fontSize: '26px'}}><b>{item.heading}</b></IonLabel>
    //                         </IonRow>
    //                     }
    //                     <>
    //                         {getCart()}
    //                     </>
    //                 </>
    //             )
    //         }
    //         else if (currentSel === 'Registration') {
    //             return (
    //                 <>
    //                     <RegistrationPage storeData={storeData} setStoreData={setStoreData}
    //                                       item={item} eventcode={eventcode} auctioncode={auctioncode} />
    //                 </>
    //             )
    //         }
    //         else if (currentSel === item.tabname) {
    //             return (
    //                 <>
    //                     {item?.heading?.length > 0 &&
    //                         <IonRow>
    //                             <IonLabel style={{marginLeft: '15px', marginTop: '10px', fontSize: '26px'}}><b>{item.heading}</b></IonLabel>
    //                         </IonRow>
    //                     }
    //
    //                     {item.sections.map((section) => {
    //                         if (section && section.sectionheading && validSectionData(section.data)) {
    //                             return (
    //                                 <>
    //                                     <IonRow>
    //                                         <IonLabel style={{marginLeft: '15px', marginTop: '10px', fontSize: '26px'}}><b>{section.sectionheading}</b></IonLabel>
    //                                     </IonRow>
    //                                     {getDataItems(section.data)}
    //                                 </>
    //                             )
    //                         }
    //                     })}
    //                 </>
    //             )
    //         }
    //         else {
    //             console.log('something else')
    //         }
    //     })
    // }



    const inputRef = useRef<HTMLIonInputElement>(null);

    return (
        <>
            <IonToast
                isOpen={toastState.display}
                onDidDismiss={() => {
                    if (toastState.goback) {
                        history.goBack()
                    }
                    setToastState({
                        display: false,
                        goback: false,
                        message: ''
                    })
                }}
                cssClass={'custom-toast'}
                message={toastState.message}
                duration={3000}
            />
            {quantityModal.active &&
                <IonModal
                    isOpen={true}
                    onWillDismiss={(ev) => onWillDismiss(ev)}
                >
                    <IonBackdrop />
                    <ModalContent>
                        <h1>{"Insuffcient Quantity Available"}</h1>
                        <h2>{"We're sorry, but the quantity available is lower than you seleced:"}</h2>
                        <IonRow style={{marginTop: '20px'}}>
                            <IonCol size="6" className="ion-justify-content-begin">
                                <Text textAlign="right"  fontWeight="800" fontSize="18px" color="#a2a2a2" >
                                    {quantityModal.item + ':'}
                                </Text>
                            </IonCol>
                            <IonCol size="6">
                                    <Text textAlign="left" fontWeight="800" fontSize="18px" color="#a2a2a2">
                                        {quantityModal.quantity + ' Remaining'}
                                    </Text>
                            </IonCol>
                        </IonRow>
                    </ModalContent>
                    <IonRow style={{marginBottom: '20px'}}>
                        <IonCol style={{display: 'flex'}} className="ion-justify-content-center">
                            <IonButton onClick={() => { setQuantityModal({active: false, quantity: 0, item:''})}} size="large" style={{ width: '80%' }} expand="block" fill="solid" color={'primary'}>
                                Ok
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonModal>
            }
            {maxQuantityPerTransModel.active &&
                <IonModal
                    isOpen={true}
                    onWillDismiss={(ev) => onWillDismiss(ev)}
                >
                    <IonBackdrop />
                    <ModalContent>
                        <h1>{"Max quantity per transaction exceeded"}</h1>
                        <h2>{"We're sorry, but the max quantity per transaction was exceeded:"}</h2>
                        <IonRow style={{marginTop: '20px'}}>
                            <IonCol size="6" className="ion-justify-content-begin">
                                <Text textAlign="right"  fontWeight="800" fontSize="18px" color="#a2a2a2" >
                                    {maxQuantityPerTransModel.name + ':'}
                                </Text>
                            </IonCol>
                            <IonCol size="6">
                                <Text textAlign="left" fontWeight="800" fontSize="18px" color="#a2a2a2">
                                    {maxQuantityPerTransModel.maxquantity + ' Max'}
                                </Text>
                            </IonCol>
                        </IonRow>
                    </ModalContent>
                    <IonRow style={{marginBottom: '20px'}}>
                        <IonCol style={{display: 'flex'}} className="ion-justify-content-center">
                            <IonButton onClick={() => { setQuantityModal({active: false, quantity: 0, item:''})}} size="large" style={{ width: '80%' }} expand="block" fill="solid" color={'primary'}>
                                Ok
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonModal>
            }

            <IonContent style={{height: '100%'}}>
                <IonHeader>
                    <IonToolbar>
                        <IonButton expand="full" className="ion-color-secondary ion-text-center ion-text-wrap ion-align-items-center ion-wrap"
                                   ion-button fill="clear">
                            <Text
                                fontSize="18px"
                                color="#000"
                                fontWeight="bold"
                                textAlign="center"
                                flexWrap={'wrap'}
                            >
                                {storeData?.storewelcomeeventname}
                            </Text>
                        </IonButton>
                    </IonToolbar>

                    <IonToolbar>
                        <IonRow>
                            {storeData && storeData?.tabs && storeData?.tabs.map((item, index) => {
                                return (
                                    <IonCol onClick={() => {
                                        if (item.enabled) {
                                            setSelectedTab(item.tabname)
                                        }
                                    }}>
                                        <IonTabButton disabled={!item.enabled} selected={selectedTab === item.tabname}>
                                            <FontAwesomeIcon size="3x" icon={item.image}/>
                                            <IonLabel>{item.tabname}</IonLabel>
                                        </IonTabButton>
                                    </IonCol>
                                )
                            })}
                        </IonRow>
                    </IonToolbar>
                </IonHeader>
                <IonGrid>
                {storeData && storeData?.tabs && storeData?.tabs.map((item, index) => {
                    if (item.tabname !== selectedTab) {
                        return;
                    }
                    // if (item.tabname === 'Cart' && selectedTab === 'Cart' && item.heading === checkoutTab) {
                    if (item.tabname === 'Cart') {
                        return (
                            <>
                                {item?.heading?.length > 0 &&
                                    <IonRow>
                                        <IonLabel style={{marginLeft: '15px', marginTop: '10px', fontSize: '26px'}}><b>{item.heading}</b></IonLabel>
                                    </IonRow>
                                }
                                <>
                                    {getCart()}
                                </>
                            </>
                        )
                    }
                    else if (item.tabname === 'Registration' ) {
                        return (
                            <>
                                <RegistrationPage storeData={storeData} setStoreData={setStoreData}
                                                  item={item} eventcode={eventcode} auctioncode={auctioncode} />
                            </>
                        )
                    }
                    else if (selectedTab === item.tabname) {
                        return (
                            <>
                                {item?.heading?.length > 0 &&
                                    <IonRow>
                                        <IonLabel style={{marginLeft: '15px', marginTop: '10px', fontSize: '26px'}}><b>{item.heading}</b></IonLabel>
                                    </IonRow>
                                }

                                {item.sections.map((section) => {
                                    if (section && section.sectionheading && validSectionData(section.data)) {
                                        return (
                                            <>
                                                <IonRow>
                                                    <IonLabel style={{marginLeft: '15px', marginTop: '10px', fontSize: '26px'}}><b>{section.sectionheading}</b></IonLabel>
                                                </IonRow>
                                                {getDataItems(section.data)}
                                            </>
                                        )
                                    }
                                })}
                            </>
                        )
                    }
                })}
                    {getButtons()}
                </IonGrid>
            </IonContent>
        </>
    )
}

export default RegisterPage