import styled from "@emotion/styled"
import {IonCol, IonGrid, IonRefresher, IonRefresherContent, IonRow} from "@ionic/react"
import React, { useEffect, useState } from "react"
import {useHistory, useParams} from "react-router"
import CheckBox from "../../components/CheckBox/Index"
import ButtonItem from "../CommonStyles/ButtonItem"
import Text from "../CommonStyles/Text"
import {IonToast} from "@ionic/react";
import waveBluePng from "../../assets/img/svg/wave-blue.png";
import {
  fetchCartData,
  setAllAuctionsChecked,
  setAuctionsWonCheckedItem,
  updateTotalAmount
} from "../../redux/slices/cartSlice"
import { CART_ENDPOINT, getEndPoint } from "../../util/api/enpoints"
import { useDispatch, useSelector } from "react-redux"
import {useAuthRefresh} from "../../hooks/authenicationHook";
import {Link} from "react-router-dom";

const EventNameContainer = styled.div`
  background-color: #b6d9ee;
  width: 100%;
  min-height: 45px;
`
const WaveContainer = styled.div`
  background: url(${waveBluePng}) no-repeat;
  width: 100%;
  min-height: 45px;
  background-size: 100% 100%;
`

type ItemWon = {
  itemid: number
  name: string
  description: string
  itemnum: string
  quantity: number
  amount: number
  location: string
  pickinstructions: string
}

type ItemWonList = {
  itemid: number
  historyid: number
  name: string
  description: string
  itemnum: string
  quantity: number
  amount: number
  location: string
  pickinstructions: string
  checked: boolean
}

const Span = styled.span`
  padding-left: 0.5em;
`

interface Params {
  eventcode: string
}

const CartPage: React.FC = () => {
  const eventState = useSelector((state: any) => state.event)
  const { eventcode } = useParams<Params>()
  useAuthRefresh()

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    const elementScroll = document.getElementById("scrollBody")
    if (elementScroll) {
      elementScroll.scroll(0, 0)
    }
  }, [])

  const cartState = useSelector((state: any) => state.cart)

  useEffect(() => {
    if (cartState.status === "idle") {
      dispatch(fetchCartData(getEndPoint(CART_ENDPOINT)))
    }
  }, [cartState.status])

  useEffect(() => {
    if (cartState.status === "success") {
      dispatch(fetchCartData(getEndPoint(CART_ENDPOINT)))
    }
  }, [])

  useEffect(() => {
    if (cartState.auctionswon && cartState.auctionswon.length > 0) {
      if (cartState.auctionsWonChecked === null) {
        dispatch(setAllAuctionsChecked())
      }
    }
  }, [cartState])

  const handleCheckboxChange = (e: any) => {
    const historyid = parseInt(e.target.id)
    dispatch(setAuctionsWonCheckedItem({ historyid, checked: e.target.checked }))
    dispatch(updateTotalAmount({ historyid, checked: e.target.checked }))
  }

  const calcAmountWon = (itemsWon: any) => {
    var wonAmount = 0
    itemsWon?.forEach((item: ItemWon) => {
      wonAmount += item.amount * item.quantity
    })
    return wonAmount
  }

  const calcChecked = () => {
    var wonAmount = 0
    cartState.auctionsWonChecked.forEach((item: ItemWonList) => {
      if (item.checked) {
        wonAmount += item.amount * item.quantity
      }
    })
    return wonAmount
  }

  /** Depends on where are we showing checked items */
  const calcOpenBids = (itemsWon: any) => {
    return cartState.auctionsopen ? cartState.auctionsopen.length : 0
  }
  const [toastObj, setToast] = useState({ display: false, message: '', goBack: false });

  const goToBids = () => {
    console.log(cartState)
    var items = cartState.auctionsWonChecked?.filter(
      (item) => { 
        console.log(item);
        return item.checked}
    )
    // history.push("/open-bids")
    if(items.length > 0) {
      localStorage.setItem('cartitems', JSON.stringify(items));
      history.push("/" + eventcode + "/checkout/1")
    }
    else {
      setToast({ display: true, message: 'Please select at least one item to checkout.', goBack: false })
    }
  }

  const formattedLine = (item: any) => {
    const formStr = (item.itemnum && item.itemnum.length > 0) ?
        "#" + item.itemnum + ", Qty" + item.quantity :
        "Qty" + item.quantity
    return formStr
  }

  interface RefresherEventDetail {
    complete(): void;
  }

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    setTimeout(() => {
      // Any calls to load data go here
      if (cartState.status === "idle") {
        dispatch(fetchCartData(getEndPoint(CART_ENDPOINT)))
      }
      event.detail.complete();
    }, 1000);
  }

  return (
    <>
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent refreshingSpinner={'circular'} refreshingText={'Loading...'}></IonRefresherContent>
      </IonRefresher>
      <EventNameContainer>
        <Text
            fontSize="35px"
            fontWeight="bold"
            textAlign="center"
            padding="24px 0 16px 0"
        >
          My Cart
        </Text>
      </EventNameContainer>
      <WaveContainer></WaveContainer>
      <IonToast
            isOpen={toastObj.display}
            message={toastObj.message}
            duration={3000}
            cssClass={'custom-toast'}
        />
      {cartState.auctionsWonChecked && cartState.auctionsWonChecked.length > 0 && cartState.status === 'success' ? (
        <>
          <IonGrid>
            <IonRow className="ion-justify-content-center">
              <Text fontWeight="800" fontSize="14px" color="#a2a2a2">
                Items Won ({cartState.auctionsWonChecked.length})
              </Text>
              <Text fontSize="14px" fontWeight="800" padding="0 0 0 10px">
                ${calcAmountWon(cartState.auctionsWonChecked)}
              </Text>
            </IonRow>
          </IonGrid>
          <IonGrid>
            <IonRow>
              <IonCol>
                {/** Do we need this button and if yes where do we render open items */}
                {/* <ButtonItem
                  height="45px"
                  backgroundColor="#ffffff"
                  color="#d2222d"
                  onClick={goToBids}
                >
                  View Your {calcOpenBids(cartState.auctionsWonChecked)} Open
                  Bids
                </ButtonItem> */}
              </IonCol>
            </IonRow>
          </IonGrid>
          {cartState.gemisvalid &&
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <ButtonItem height="45px" onClick={goToBids}>
                      Proceed To Checkout
                    </ButtonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>
          }

          {cartState.auctionsWonChecked.map((item: ItemWonList) => {
            return (
              <IonGrid key={item.itemid}>
                <IonRow>
                  <IonCol size="1">
                    <div className="ion-text-left">
                      <label>
                          <CheckBox
                            name={item.name}
                            checked={item.checked}
                            handleCheckboxChange={handleCheckboxChange}
                            param="item"
                            checkedBackgroundColor="#5732db"
                            uncheckedBackgroundColor="white"
                            height="24px"
                            width="24px"
                            borderRadius="50%"
                            id={item.historyid}
                          ></CheckBox>
                      </label>
                    </div>
                  </IonCol>
                  <IonCol size="6">
                    <div className="ion-text-left">
                      <Text fontSize="14px" fontWeight="800">
                        <Span>{item.name}</Span>
                      </Text>
                    </div>
                  </IonCol>
                  <IonCol size="3">
                    <div className="ion-text-right">
                      <Text fontWeight="800" fontSize="20px">
                        ${item.amount}
                      </Text>
                    </div>
                    </IonCol>
                </IonRow>
                <IonRow style={{ paddingLeft: "35px" }}>
                  <IonCol
                    className="ion-no-padding"
                    style={{ borderBottom: "1px solid #dadada" }}
                  >
                    <Text fontSize="14px" color="#a2a2a2">
                      {formattedLine(item)}
                    </Text>
                  </IonCol>
                </IonRow>
              </IonGrid>
            )
          })}
          <IonGrid>
            <IonRow
              className="ion-justify-content-evenly ion-align-items-center"
              style={{
                backgroundColor: "#0000000D",
                height: "45px",
                borderRadius: "10px"
              }}
            >
              <Text fontWeight="800" fontSize="14px" color="#a2a2a2">
                Selected for checkout (
                {
                  cartState.auctionsWonChecked.filter(
                    (obj: ItemWonList) => obj.checked === true
                  ).length
                }
                /{cartState.auctionsWonChecked.length} Items):
              </Text>
              <Text fontSize="14px" fontWeight="800" padding="0 0 0 10px">
                ${calcChecked()}
              </Text>
            </IonRow>
          </IonGrid>
        </>
      ) :
       <>
          <Text textAlign="center" padding="1rem">Your cart is empty.</Text>
          <Text textAlign="center" padding="1rem">Items won will show up here once bidding closes or the Buy It Now option is used.</Text>
       </>
      }
      <div style={{width: '75%', margin: 'auto', borderTop: "1px solid #666", marginTop: '50px'}}>
        <div style={{verticalAlign: 'middle', color: '#666', marginTop: '5px', marginBottom: "0px", backgroundColor: '#f2f2f2', position: "relative", padding:"0 0 0 0", textAlign: "center", fontSize: 12}}>
          AuctionSnap™. All Rights Reserved by DonorSnap.{" "}<br />
          <span style={{ color: "red" }}>
                    <a><Link style={{ textDecoration: 'none' }} to={"/" + eventState.eventcode + "/privacy-policy"}>Privacy Policy</Link></a>
                  </span>
        </div>
      </div>
    </>
  )
}
export default CartPage
