import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { client } from "../../util/api/fetchData"

type EventState = {
  eventData: any
  eventflag: string
  eventname: string
  eventopendatecst: string
  eventcloseddatecst: string
  eventbiddingopencst: string
  eventbiddingclosedcst: string
  eventopendate: string
  eventcloseddate: string
  eventbiddingopen: string
  eventbiddingclosed: string
  eventopendatecd: string
  eventcloseddatecd: string
  eventbiddingopencd: string
  eventbiddingclosedcd: string
  organizationphone: string
  organizationemail: string
  allowbillme: Boolean
  allowpayccfees: Boolean
  eventallowanonymousbidding: Boolean
  eventallowguestentry: Boolean
  ccfeespercent: Boolean
  welcomemessage: string
  homemessage: string
  contactus: string
  logourl: string
  sponsorurl: any
  addressrequired: Boolean
  ziprequired: Boolean
  status: "idle" | "loading" | "succeeded" | "failed"
  error: any
  eventcode: any
  auctioncode: any
  creditcardiframe: string
  javascriptfile:  string
  transcenterid: string
  processorid: string
  transactiontype: string
  notificationcolumns: []
  notificationrows: []
  notifications: {
    shownotificationsmenuitem: Boolean
  }
  reset: Boolean
  cartreset: Boolean
  forcelogout: Boolean
  eventclosing: Boolean
}

const initialState: EventState = {
  eventData: {},
  eventflag: "",
  eventname: "",
  eventopendatecst: "",
  eventcloseddatecst: "",
  eventbiddingopencst: "",
  eventbiddingclosedcst: "",
  eventopendate: "",
  eventcloseddate: "",
  eventbiddingopen: "",
  eventbiddingclosed: "",
  eventopendatecd: "",
  eventcloseddatecd: "",
  eventbiddingopencd: "",
  eventbiddingclosedcd: "",
  organizationphone: "",
  organizationemail: "",
  allowbillme: false,
  allowpayccfees: false,
  eventallowanonymousbidding: false,
  eventallowguestentry: false,
  ccfeespercent: false,
  welcomemessage: "",
  homemessage: "",
  contactus: "",
  logourl: "",
  sponsorurl: [],
  addressrequired: false,
  ziprequired: false,
  status: "idle",
  error: null,
  eventcode: null,
  auctioncode: null,
  creditcardiframe: "",
  javascriptfile:  "",
  transcenterid: "92754",
  processorid: "70802",
  transactiontype: "Sale",
  notificationcolumns: [],
  notificationrows: [],
  notifications: {
    shownotificationsmenuitem: false,
  },
  reset: false,
  cartreset: false,
  forcelogout: false,
  eventclosing: false
}

function hasKey<O>(obj: O, key: keyof any): key is keyof O {
  return key in obj
}

// export const fetchEventData = createAsyncThunk(
//   "event/fetchEventData",
//   async (url: string) => {
//     const response = await client.get(url)
//     const parsedData = JSON.parse(response.jsonString)
//     return parsedData
//   }
// )

export const fetchEventData = createAsyncThunk(
    "event/fetchEventData",
    async (config: any) => {
      const response = await client.post(config.url, {
        email: config.email,
        password: config.password,
        eventcode: config.eventcode,
        auctioncode: config.auctioncode
      })
      const parsedData = JSON.parse(response.jsonString)
      return parsedData
    }
)

export const slice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setEventReset: (state, action) => {
      state.reset = action.payload
    },
    setCartReset: (state, action) => {
      state.cartreset = action.payload
    },
    setForceLogout: (state, action) => {
      state.forcelogout = action.payload
    },
    setEventClosing: (state, action) => {
      state.eventclosing = action.payload
    },
    setEventCode: (state, action) => {
      state.eventcode = action.payload
    },
    setAuctionCode: (state, action) => {
      state.auctioncode = action.payload
    },
    setAuctionIds: (state, action) => {
      state.eventcode = action.payload.eventcode
      state.auctioncode = action.payload.auctioncode
    }
  },
  extraReducers: (builder) => {
    builder.addCase("event/fetchEventData/pending", (state, action) => {
      state.status = "loading"
    })
    builder.addCase("event/fetchEventData/fulfilled", (state, action: any) => {
      Object.keys(state).forEach(key => {
        if (key in action.payload) {
          if (hasKey(state, key)) {
            let value = action.payload[key]
            state[key] = value
          }
        }
      })
      state.status = "succeeded"
      state.eventData = action.payload
    })
    builder.addCase("event/fetchEventData/rejected", (state, action: any) => {
      state.status = "failed"
      state.error = action.error.message
    })
  }
})

export const { setEventCode, setAuctionCode, setAuctionIds, setEventReset, setCartReset, setForceLogout, setEventClosing } = slice.actions
export default slice.reducer
